import React, { FC } from 'react';
import { graphql, PageProps } from 'gatsby';

// components
import Page from '@components/Page';

// types
import type { Sb } from '@types';

// layout
import Layout from '@layout';

// custom hooks
import useStoryblok, { isStory } from '@hooks/useStoryblok';

type HomePageProps = PageProps<{
  story: Sb.GraphQLStoryNode;
}>;

const Home: FC<HomePageProps> = ({ data, location }) => {
  const [story] = useStoryblok(data, location.search);

  if (!isStory(story)) return <div>Not found</div>;

  return (
    <Layout location={location}>
      <Page blok={story.content} />
    </Layout>
  );
};

export const query = graphql`
  query {
    story: storyblokEntry(full_slug: { eq: "home" }) {
      id
      name
      slug
      field_component
      full_slug
      content
      created_at(fromNow: true)
      group_id
      is_startpage
      parent_id
      position
      published_at
      first_published_at
      tag_list
      uuid
    }
  }
`;

export default Home;
